@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Kristen ITC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

.jamb-button,
.jamb-btn {
  display: inline-block;
  background-color: rgb(23, 191, 253);
  border: 1px solid rgb(23, 191, 253);
  color: #fff;
  padding: 0.5rem 1rem;
}

.jamb-btn {
  border-radius: 5px;
}

.jamb-button-outlined {
  display: inline-block;
  border: 1px solid rgb(23, 191, 253);
  color: rgb(23, 191, 253);
  padding: 0.5rem 1rem;
}

.jamb-button:hover {
  background-color: #fff;
  color: rgb(23, 191, 253);
}

.jamb-button-outlined:hover {
  background-color: rgb(23, 191, 253);
  color: #fff;
}

.playhouse-register-btn {
  background-color: #85E327;
}

.playhouse-register-btn:hover {
  background-color: #92fa2b;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  border: 2px solid rgb(23, 191, 253);
  padding: 5px 10px;
  border-radius: 5px;
  color: rgb(23, 191, 253);
}

#peek-carousel .owl-stage-outer {
  border-radius: 10px;
}

#peek-carousel .owl-theme .owl-dots .owl-dot.active span {
  background: rgb(23, 191, 253);
}

.lazy-load-image-background {
  display: block !important;
}

.slideOutBtn {
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.slideOutBtn:hover {
  transform: translateY(8px)
}

.close-icon {
  left: -32px;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}

.close-icon:hover {
  opacity: 1;
}

.close-icon:before,
.close-icon:after {
  position: absolute;
  left: 16px;
  top: 0;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}

.close-icon:before {
  transform: rotate(45deg);
}

.close-icon:after {
  transform: rotate(-45deg);
}

.contact-bg-left {
  background-image: url('https://www.myjamboreegh.com/images/characters/con-tree.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: -150px;
  width: 500px;
  height: 600px;
  position: fixed;
  top: 180px;
  bottom: 0;
  left: 0;
}

.contact-bg-right {
  background-image: url('https://www.myjamboreegh.com/images/characters/con-flower.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 100px;
  width: 500px;
  height: 600px;
  position: fixed;
  top: 150px;
  bottom: 0;
  right: 0;
}

.pastel-section{
  padding: 20px 0px;
  /* margin-bottom: 20px; */
}

.pastel-board{
  position: relative;
  overflow: hidden;
  font-family: Delius, 'Gloria Hallelujah', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-bottom: 100px;
  /* color: #fff; */
  color: #000;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 0px;
  padding-bottom: 200px;
}

/* .pt-page-2 .pastel-board{
  font-weight: bold;
} */
.pastel-board{
  font-weight: bold;
}

.pastel-section p{
  /* font-size: 1.2em; */
}

.pastel-pink{
  background-color: rgb(225,153,173);
}

.pastel-orange{
  background-color: rgb(232,192,61);
}

.pastel-green{
  background-color: rgb(84,208,146);
}

.pastel-blue{
  background-color: rgb(10,212,222);
}

.pastel-purple{
  background-color: rgb(167, 125,207);
}
.pastel-yellow{
  background-color: #eae279;
}

.emo{
  font-size: 1.8em;
}

.paper p{
  font-size: 1.3em;
}

.section-about .paper img{
  max-width: 850px;
}

.jamboree-text {
  font-size: 1.5em;
}

.pink{
  color: #ec008c;
}

.green{
  color: #8dc63f;
}

.orange{
  color: #f5a720;
}

.blue{
  color: #27aae1;
}

.red{
  color: #ef4136;
}

.j-red{
  color: #e9272a;
}

.j-orange{
  color: #f4a61f;
}

.j-purple{
  color: #b8519e;
}

.j-blue{
  color: #4d69b1;
}

.j-yellow{
  color: #ffd34f;
}

.j-green{
  color: #8ac64a;
}

.j-pink{
  color: #f06ca8;
}

.j-lblue{
  color: #66ccf2;
}

.growThumb {
  height: 300px;
}

@media screen and (max-width: 593px) {
  .newsletter-modal {
    background-image: none !important;
  }
}

@keyframes diving-rotate {
  0% {
         transform:rotate(0deg); 
  }
    50% {
         transform:rotate(3deg); 
  }
    75% {
        transform:rotate(-2deg); 
  }
    100% {
        transform:rotate(0deg); 
  }
}

.ghost {
  animation: float 3s ease-out infinite;
}

@keyframes float {
  50% {
     transform: translate(0, 20px);
  }
}
.ghost2 {
  animation: float2 3s ease-out infinite;
}

@keyframes float2 {
  50% {
     transform: translate(0, 5px);
  }
}

.btn-fff, .btn-000 {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 5px;
  transition: all 0.2s ease-in;
}

.btn-fff{
  border: 1px solid #fff;
}

.btn-000{
  border: 1px solid #000;
}

.btn-fff:hover{
  background: #fff;
  color: orange;
}

.btn-000:hover{
  background: #000;
  color: orange;
}

.styles_wrapper__1kG3C {
  background-color: #fff !important;
}

.styles_gridItem__nPXhF figure > div > img {
  opacity: 1 !important;
}